import React from "react"

import "../styles/bootstrap.min.css"
import "./index.css"

import background from '../assets/background-minminminmin.png';
// import burger from '../assets/burger.png';
import plaiz from '../assets/plaiz.svg';
import cloud from '../assets/cloud.svg';
import circle from '../assets/circle.svg';
// import beta from '../assets/beta.svg';
import bouton from '../assets/bouton.svg';
import apple from '../assets/apple.svg';
import android from '../assets/android.svg';
import melty from '../assets/img/melty.png';
import ninki from '../assets/img/ninki.png';
import cocy from '../assets/img/cocy.png';
import essec from '../assets/img/essec.png';
import schoolab from '../assets/img/schoolab.png';
import frenchtech from '../assets/img/frenchtech.png';
import forbes from '../assets/img/forbes.svg';
import vogue from '../assets/img/vogue.svg';
import asos from '../assets/img/asos.svg';

//import communautephotos from '../assets/img/communautephotos.gif';
import communautephotos from '../assets/img/discover2-minminmin.png';
import profilphoto from '../assets/img/profil-min-2.png';
// import photo1 from '../assets/gallerie/photo1-minminmin.png';
// import photo2 from '../assets/gallerie/photo2-minminmin.png';
// import photo3 from '../assets/gallerie/photo3-minminmin.png';
// import photo4 from '../assets/gallerie/photo4-minminmin.png';
// import photo5 from '../assets/gallerie/photo5-minminmin.png';
// import photo6 from '../assets/gallerie/photo6-minminmin.png';
import PAFW from '../assets/PAFW.svg';
// import homme from '../assets/homme.png';
import femmeCercle from '../assets/femmeCercle.svg';
import bouton2 from '../assets/bouton2.svg';
import screenshot from '../assets/screenshot/screenshot-minmin.png';
import facebook from '../assets/reseauxsociaux/facebook.svg';
// import snapchat from '../assets/reseauxsociaux/snapchat.svg';
import instagram from '../assets/reseauxsociaux/instagram.svg';
import linkedin from '../assets/reseauxsociaux/linkedin.svg';
import youtube from '../assets/reseauxsociaux/youtube.svg';
import defile from '../assets/img/defile.gif';


import florian from '../assets/florian.jpg';
import billel from '../assets/billel.jpg';
import bouki from '../assets/bouki.jpg';
import clem from '../assets/clem.png';

// import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
import { Link } from "gatsby"
//import rond_color from "../assets/rond_color.svg"
import testimonialsFrench from "../assets/testimonialsFrench-min.png"
import { Helmet } from "react-helmet";

const IndexPageClassic = () => (
  <div>
    <SEO title="Plaiz - Le Réseau Social De La Mode"/>
    <div className="App">

      <header className="App-header">
          <Helmet>
            {/* <!-- Begin Inspectlet Asynchronous Code --> */}
            {/* <script type="text/javascript">
              (function() {
                window.__insp = window.__insp || [];
                __insp.push(['wid', 635824570]);
                var ldinsp = function(){
                if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=635824570&r=' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
                setTimeout(ldinsp, 0);
              })();
            </script> */}
            <script dangerouslySetInnerHTML= {{ __html: ` 
              (function() {
                window.__insp = window.__insp || [];
                __insp.push(['wid', 635824570]);
                var ldinsp = function(){
                if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=635824570&r=' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
                setTimeout(ldinsp, 0);
                })() `}} />
            {/* <!-- End Inspectlet Asynchronous Code --> */}
          </Helmet>
          <script dangerouslySetInnerHTML= {{ __html: ` 
              (function() {
                window.__insp = window.__insp || [];
                __insp.push(['wid', 635824570]);
                var ldinsp = function(){
                if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js?wid=635824570&r=' + Math.floor(new Date().getTime()/3600000); var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
                setTimeout(ldinsp, 0);
                })() `}} />
          <div>
            <img src={background} className="background"  alt="logo"/> 
          </div>
          <div className="logo-and-burger">
            <img src={plaiz} className="logo-plaiz"  alt="logo"/> 
            {/* <Link to='/menu'>
            <img src={burger} className="burger-menu"  alt="logo"/>  
            </Link> */}
            <Link to={"/eng"} className="english-version">Switch to English version 🇬🇧</Link>
          </div>

          <div class="splash">
            <img src={plaiz} className="splash-plaiz"  alt="logo"/> 
            <p className="splash-titre">PLAIZ</p>
            {/* <p className="splash-soustitre">Le réseau social de la mode</p> */}
          </div>

          <div className="texte-bloc-header">
            <p className="header-titre">PLAIZ</p>
            <p className="header-titre2">L'anti-réseau social<br/>100% mode</p>
            <div>
              <img src={communautephotos} className="heroscreenshot" alt="img" />
            </div>
          </div>
          <div className="premier-bloc-download" >
            <p className="telecharge-l-app">telecharge l'app ! </p>
            <div className="telecharge-l-app-bouton">
              <a target="_blank" href="https://apps.apple.com/fr/app/plaiz/id1262762421">
                <img src={bouton} className="bouton-1"  alt="logo"/>
                <img src={apple} className="apple"  alt="logo"/>
              </a>
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.plaiz.appli&hl=fr"> 
                <img src={bouton} className="bouton-2"  alt="logo"/>
                <img src={android} className="android"  alt="logo"/>
              </a>
            </div>
            <div className="gratuit">
            <p className="gratuit-pour">Gratuite pour toujours pour les 100 000 premiers !</p>
            </div>
          </div>
        <div className="milieu-svg">
          <div className="cote-gauche">
            <img src={circle} className="circle"  alt="logo"/> 
          </div>
          <div className="cote-droit">
            <img src={cloud} className="cloud"  alt="logo"/> 
          </div>
        </div>

        <div className="scrolling-wrapper">
          <div className="partenaires">
            <div className="partenaires-scroll-frenchtech">
              <img src={vogue} className="partenaires-scroll" alt="logo" />
            </div>
            <div className="partenaires-scroll-ninki">
              <img src={asos} className="partenaires-scroll" alt="logo" />
            </div>
            <div className="partenaires-scroll-melty">
              <a target="_blank" href="https://www.airofmelty.fr/plaiz-l-anti-reseau-social-qui-entend-conquerir-la-jeune-generation-passionnee-de-mode-a707153.html">
                <img src={melty} className="partenaires-scroll"  alt="logo" />
              </a>
            </div>
            <div className="partenaires-scroll-ninki" >
              <a target="_blank" href="https://ninkimag.fr/plaiz-la-nouvelle-appli-sensationnelle-pour-les-fans-de-mode/">
                <img src={ninki} className="partenaires-scroll-high" alt="logo" />
              </a>
            </div>
            <div className="partenaires-scroll-frenchtech">
              <img src={forbes} className="partenaires-scroll" alt="logo" />
            </div>
            <div className="partenaires-scroll-cocy">
              <a target="_blank" href="https://cocy.fr/2017/10/09/plaiz-lapplication/">
                <img src={cocy} className="partenaires-scroll-high" alt="logo" />
              </a>
            </div>
            <div className="partenaires-scroll-essec">
              <a target="_blank" href="https://www.essec.edu/fr/">
                <img src={essec} className="partenaires-scroll" alt="logo" />
              </a>
            </div>
            <div className="partenaires-scroll-schoolab">
              <a target="_blank" href="https://theschoolab.com/">
                <img src={schoolab} className="partenaires-scroll" alt="logo" />
              </a>
            </div>
            <div className="partenaires-scroll-frenchtech">
              <a target="_blank" href="https://lafrenchtech.com/fr/">
                <img src={frenchtech} className="partenaires-scroll" alt="logo" />
              </a>
            </div>
          </div>
        </div>

      </header>

      <div className="herodiv">
        <img src={communautephotos} className="heroscreenshot2" alt="img" />
      </div>
      
      <body>
        <div className="bloc-concept">
          <p className="plaiz-titre">Plaiz</p>
          <p className="plaiz-concept-titre">le concept</p>
        </div>
        <div className="bloc-concept-2">
          <p className="bloc-concept-2-texte">Partage tes outfits sans prise de tête à une communauté qui te correspond, sans pression, sans jugement. On préfère la créativité à la popularité, donc les likes sont cachés, et on n'a pas de système de followers, donc pas besoin de construire ton audience, dis-nous ce que tu aimes, on s'occupe du reste.</p>
          {/* <a className="product-hunt" href="https://www.producthunt.com/posts/plaiz-fashion-anti-social-network?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-plaiz-fashion-anti-social-network" target="_blank">
            <img className="product-hunt" src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=192231&theme=dark" alt="Plaiz - Fashion Anti-Social Network - 100% outfits, no likes, no followers, no headaches | Product Hunt Embed"/>
          </a> */}
        </div>

        <div className="bloc-download" >
          <p className="text-download">Télécharger l'app :</p>
          <div className="button-and-icon">
            <a target="_blank" href="https://apps.apple.com/fr/app/plaiz/id1262762421">
              <img src={apple} className="icon"  alt="logo"/>
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.plaiz.appli&hl=fr"> 
              <img src={android} className="icon"  alt="logo"/>
            </a>
          </div>
          <div style={{paddingTop:20, fontSize:12}}>⭐⭐⭐⭐<span style={{opacity:0.7}}>⭐</span></div>
          <div className="stars-on-store">4,7/5 sur les stores</div>
        </div>

        {/* <div className="bloc-plaiz-is" >
          <p className="text-plaiz-is-title">Plaiz c'est l'endroit parfait pour:</p>
          <div className="plaiz-is-full">
            <div className="plaiz-is-half">
              <p className="text-plaiz-is">• Poster tous tes outfits, même ceux qui sont pas assez “parfait” pour les autres réseaux, les selfies miroir ou d’ascenseur qu’on a tous.</p>
             
              <p className="text-plaiz-is">• T’exprimer de manière authentique et sans jugement.</p>
              <p className="text-plaiz-is">• Parler de mode, même sans être un expert.</p>
              <p className="text-plaiz-is">• Tester des styles nouveaux et être la version la plus audacieuse et créative de toi-même.</p>
            </div>
            <div className="plaiz-is-half-2">
              <p className="text-plaiz-is">• Faire des revues d’articles que tu achètes ou de shops que tu visites.</p>
              <p className="text-plaiz-is">• Sauvegarder les styles qui t'inspirent, postés par des personnes comme toi, pas uniquement des superstars (oui, Beyoncé n'est pas encore sur Plaiz).</p>
              <p className="text-plaiz-is">• Rassembler tes inspirations puisées un peu partout et montrer ce qui te plait artistiquement parlant.</p>
              <p className="text-plaiz-is">• Découvrir l'univers d'un tas de marques, de créatifs, et leur montrer le tien.</p>
            </div>
          </div>
        </div> */}

        <div className="communaute">
          <div className="communaute-gauche">
            <p className="communaute-gauche-titre">Rejoins ta </p> 
            <p className="communaute-gauche-titre-2"> communauté de style </p> 
            <p className="communaute-gauche-texte">
              Découvre l'univers des amateurs et passionnés de mode présents sur l'app, des designers, marques émergentes, photographes, mannequins, du novice au puriste (pas besoin d'être un expert pour parler de mode sur Plaiz).
              <br/><br/>
              Tu connais la chanson : n’attends pas que tout le monde y soit pour y être, fais-toi plaiz ! (C’était facile…)
              </p>
          </div>
          <div className="communaute-droit">
            <img src={profilphoto} className="communautephotos" alt="img" />
          </div>
        </div>

        <div className="bloc-concept">
          <p className="plaiz-titre">Sans modestie,</p>
          <p className="plaiz-concept-titre">tout le monde nous adore !</p>
          <div className="bloc-testimonials">
            <img src={testimonialsFrench} className="testimonials"/> 
          </div>
        </div>

        <div className="bloc-download" >
          <p className="text-download">Rejoins-nous :</p>
          <div className="button-and-icon">
            <a target="_blank" href="https://apps.apple.com/fr/app/plaiz/id1262762421">
              <img src={apple} className="icon"  alt="logo"/>
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.plaiz.appli&hl=fr"> 
              <img src={android} className="icon"  alt="logo"/>
            </a>
          </div>
        </div>

        <img src={PAFW} className="PAFW" alt="img" />
        <div className="plaiz-week">
          <div className="plaiz-week-cote-gauche">
            <img src={defile} className="defile" style={{borderRadius:20}}/>
            <img src={femmeCercle} className="femmeCercle" alt="img" />
          </div>
          <div className="plaiz-week-cote-droit">
            <div className="plaiz-week-cote-droit-titre-responsive"> 
              <p className="plaiz-week-cote-droit-titre">Plaiz c'est aussi: <span className="plaiz-week-cote-droit-titre-2">Des évènements</span></p>
            </div>
            <p className="plaiz-week-cote-droit-texte">Des défilés, des performances live (danseurs, rappeurs, speed graffiti…), des cocktails, des soirées… La Plaiz Anti Fashion Week <span className="span">(#PAFW)</span> vous plonge pendant une semaine au coeur de l’univers underground et original des marques préférées du <span className="span"> Plaiz Gang</span>.<br/><br/>Une fashion week contre-culture, secrète, aux frontières de Paris, cachée dans un <span className="span"> hangar de 2000m2</span> aménagé pour l’occasion.<br/><br/>Rejoignez-nous pour être tenus au courant des prochains évènements ! (post-covid 😷)</p>
          </div>
        </div>
        
        {/* <div className="gallerie-plaiz">
          <div className="gallerie-plaiz-premiere-partie">
            <img src={photo1} className="photo1" alt="img"/>
            <img src={photo2} className="photo2" alt="img"/>
            <img src={photo3} className="photo3" alt="img"/>
          </div>
          <div className="gallerie-plaiz-deuxieme-partie">
            <img src={photo4} className="photo4" alt="img"/>
            <img src={photo5} className="photo5" alt="img"/>
            <img src={photo6} className="photo6" alt="img"/>
          </div>
        </div> */}

        <div className="inscription">
        <img src={circle} className="circle-inscription" alt="img" />
          <div className="inscription-gauche" >
          <div className="inscription-gauche-download">
            <div className="inscription-gauche-titre-1">
              <p className="inscription-gauche-titre">Télécharge</p>
              <p className="inscription-gauche-titre-2">l'app Plaiz</p>
            </div>
            <div className="inscription-gauche-t">
              <p className="inscription-gauche-texte">En ce moment, Plaiz se met en mode VIP 🎫 :</p>
              <p className="inscription-gauche-texte">Inscris-toi dès aujourd’hui pour être invité.e aux événements exclusifs. <span className="inscription-gauche-texte-2"> (Fais vite, les places du mode VIP sont limitées).</span> </p>
            </div>
            </div>
    
            <div className="download-middle">
              <a target="_blank" href="https://apps.apple.com/fr/app/plaiz/id1262762421"> 
                <img src={apple} className="apple-2"  alt="logo"/>
                <img src={bouton2} className="bouton-3"  alt="logo"/> 
              </a> 
              <a target="_blank" href="https://play.google.com/store/apps/details?id=com.plaiz.appli&hl=fr"> 
                <img src={android} className="android-2"  alt="logo"/>
                <img src={bouton2} className="bouton-4"  alt="logo"/>
              </a>
            </div>
          </div>
          <div className="inscription-droite">
            <img src={screenshot} className="screenshot" alt="img" />
          </div>
        </div>

        <div className="bloc-download" >
          <p className="text-not-convinced">Toujours pas prêt à nous rejoindre ?</p>
          <p className="text-follow-us">Suis-nous sur les <a className="classic-networks" href="https://www.instagram.com/plaiz_gang/">réseaux classiques</a> pour etre prévenu quand tout le monde sera sur l'app ! 🤙</p>
        </div>

        <div className="bloc-download" >
          <p className="text-not-convinced">Créé avec ❤️ et ☕️ par la team Plaiz :</p>
          <div className="our-pictures-block">
            <div style={{marginLeft:10, marginRight:10}}>
              <img src={bouki} className="our-pictures" alt="img" />
              <p>Bouki</p>
            </div>
            <div style={{marginLeft:10, marginRight:10}}>
              <img src={florian} className="our-pictures" alt="img" />
              <p>Flo</p>
            </div>
            <div style={{marginLeft:10, marginRight:10}}>
              <img src={billel} className="our-pictures" alt="img" />
              <p>Bill</p>
            </div>
            <div style={{marginLeft:10, marginRight:10}}>
              <img src={clem} className="our-pictures" alt="img" />
              <p>Clem</p>
            </div>
          </div>
          <p className="text-not-convinced">Et plein d'autres gens qu'on adore</p>
        </div>

        <div className="bloc-download" >
          <p className="text-download">Ici pour télécharger l'app 👇:</p>
          <div className="button-and-icon">
            <a target="_blank" href="https://apps.apple.com/fr/app/plaiz/id1262762421">
              <img src={apple} className="icon"  alt="logo"/>
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.plaiz.appli&hl=fr"> 
              <img src={android} className="icon"  alt="logo"/>
            </a>
          </div>
        </div>

        <div className="bloc-download" style={{marginTop:100}}>
          <p className="text-not-convinced">Fonctionnalités à venir 😱</p>
          <div style={{width:"80%", alignSelf:"center"}}>
          <p className="text-features"  style={{textAlign:"left"}}>
            💰 Gagne de l'argent en postant tes looks : on analyse tes vêtements, on propose des vêtements similaires aux visiteurs, et s'ils achètent grâce à toi, on te rémunère.
            <br/><br/><br/>
            🛍 Achète et vends tes vêtements, qu'il s'agisse de vêtements à toi, ou de création
            <br/><br/><br/>
            👘 Noue des partenariats avec les marques : comme on te garanti 6000 vues minimum, tu es d'office influenceur. Entre en relation avec nos marques via notre plateforme
            <br/><br/><br/>
            🎁 Obtiens des vêtements gratuits grâce aux membres de la communauté qui donnent leurs vêtements !
          </p>
          </div>
        </div>

        <div className="bloc-download" >
          <p className="text-download">Team iOS ou Android ?</p>
          <div className="button-and-icon" style={{marginBottom:100}}>
            <a target="_blank" href="https://apps.apple.com/fr/app/plaiz/id1262762421">
              <img src={apple} className="icon"  alt="logo"/>
            </a>
            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.plaiz.appli&hl=fr"> 
              <img src={android} className="icon"  alt="logo"/>
            </a>
          </div>
        </div>

        <div className="bloc-download" >
          <p className="text-not-convinced">Tu es une bonne personne 🥰</p>
          <p className="text-follow-us">Appuie <Link className="classic-networks" to={"/bonne-personne"}>ici</Link> pour découvrir pourquoi ✨</p>
        </div>
      </body>

      <div className="app-footer">
        <div className="footer">

          <img src={circle} className="circle-footer" alt="img" />
          
          <div className="aPropos" >
            <p className="aPropos-titre">A propos <span className="aPropos-titre-2">de Plaiz</span></p>
            <p className="aPropos-texte">Nous sommes un espace de création et de conversation entre des passionné.es du même univers mode, utilisateur.trices, marques, jeunes designers, puristes, touristes. Vous êtes tous des égéries, vous êtes tous stylé.es.</p>
            
            <div className="reseauxSociaux">
              <a target="_blank" href="https://fr-fr.facebook.com/superplaiz/"> 
                <img src={facebook} className="facebook" alt="img"/>
              </a>
              {/* <a target="_blank"> 
                <img src={snapchat} className="snapchat" alt="img"/>
              </a> */}
              <a target="_blank" href="https://www.instagram.com/plaiz_gang/?hl=fr">
                <img src={instagram} className="instagram" alt="img"/>
              </a>
              <a target="_blank" href="https://www.linkedin.com/company/plaiz">
                <img src={linkedin} className="linkedin" alt="img"/>
              </a>
              <a target="_blank" href="https://www.youtube.com/channel/UCWexBI920O8mbza0u_k0S5g">
                <img src={youtube} className="youtube" alt="img"/>
              </a>
            </div>

            <p className="reseauxSociaux-y">Lâche un pouce en Y</p>
          </div>

          <div className="contacteNous">
            <p className="contact">Contacte <span className="contact-titre">nous</span></p>
            <p className="contact-texte"> 5 parvis Alan Turing</p>
            <p className="contact-texte" > 75013 Paris. </p>
            <p className="contact-texte">06 69 77 31 61 - hello@plaiz.io</p>
          </div>

        </div>

        <div className="cloud-div">
          <img src={cloud} className="cloud-footer"  alt="logo"/>
        </div>

        <div className="bloc-mis">
          <p className="mis">Made in the streets 🌹</p>
        </div> 

      </div>

    </div>
  </div>

)

export default IndexPageClassic
